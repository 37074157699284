import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { getVFSStat } from '../api';

interface ESIMData {
  date: string;
  paidUsers: number;
  freeUsers: number;
}

const WeeklyESIMChart: React.FC = () => {
  const [chartData, setChartData] = useState<ESIMData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    getVFSStat()
      .then((response:any) => {
        const fetchedData = response.data ?? [];

        setChartData(fetchedData);
        setLoading(false);
      })
      .catch((err: Error) => {
        setError(err);
        setLoading(false);
      });
  }, []);


  if (loading) return <div style={{ color: '#ffffff' }}>Loading...</div>;
  if (error) return <div style={{ color: '#ffffff' }}>Error: {error.message}</div>;
  if (chartData.length === 0) return <div style={{ color: '#ffffff' }}>No data available</div>;

  const categories = chartData.map((item) => item.date);
  const paidUsers = chartData.map((item) => item.paidUsers);
  const freeUsers = chartData.map((item) => item.freeUsers);

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: '#ffffff', // White color for dates
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Weekly VFS eSIM Statistics',
      align: 'center',
      style: {
        color: '#ffffff', // White color for title
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff', // White color for Y-axis
        },
      },
    },
  };

  const series = [
    {
      name: 'Paid Users',
      data: paidUsers,
    },
    {
      name: 'Free Users',
      data: freeUsers,
    },
  ];

  return (
    <div className="chart-container">
      <Chart options={chartOptions} series={series} type="bar" height={350} />
    </div>
  );
};

export default WeeklyESIMChart;

// I added a fallback for empty or undefined data and a "No data available" message. Let me know if you want any changes! 🚀
