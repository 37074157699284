import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { addCountryFromSelect, deleteCountryFromDragDrop, getGeoCounries, getSortingCountryList, putGeoCounries, updateCountryFromDragDrop } from "../api";
import { Layout } from "../../../ui";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { axios } from "../../../lib/axios";
import styles from "../styles/countryRegions.module.css"
import { string } from "yup";
import { json } from "stream/consumers";
interface Country {
  id: string;
  name: string;
  flag?: string;
  iso?: string;
  attachedCountries: { countryId: string }[]; 
}

type CountryOption = { value: string; label: string; flag: string,iso?:string };

const DefaultCountries: React.FC = () => {
  const { geoName } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const countryId = searchParams.get("countryId");
  console.log('countryId: ', countryId);

  const [countries, setCountries] = useState<Country[]>([]);
  const [allCountries, setAllCountries] = useState<Country[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(null);

  useEffect(() => {
  
      fetchSortedCountries();
   
    fetchCountriesSlect(); // ✅ Call this function to populate the dropdown
  }, []);

  const fetchSortedCountries = async () => {
   
    try {
      const response = await getSortingCountryList();
      console.log('response: ', response);
      const sortedCountryList: Country[] = response?.attachedCountries.map((country: any) => ({
        id: country.countryId._id,
        name: country.countryId.name,
        flag: country.countryId.flagImageUrlFull,
        iso: country.countryId.iso,
      }));
      setCountries(sortedCountryList);
    } catch (error) {
      console.error("Error fetching sorted countries:", error);
    }
  };

  const fetchCountriesSlect = async () => {
    try {
      const response = await getGeoCounries({isDefault:true,limit:168});
      const countryList: Country[] = response?.data?.countries.map((country: any) => ({
        id: country._id,
        name: country.name,
        flag: country.flagImageUrl,
        iso:country.iso
      }));
      setAllCountries(countryList);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
  
    // Reorder the countries array
    const reordered = [...countries];
    const [movedItem] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, movedItem);
  
    // Update state with the new order
    setCountries(reordered);
  
    // Prepare data for API call
    const updatedCountries = reordered.map((country, index) => ({
      countryId: country.id,
      sortNumber: index + 1, // Assign new sort order
    }));
  console.log(JSON.stringify(updatedCountries))
    // Make API call
   
      updateCountryFromDragDrop(null, updatedCountries);

  };
  

  const handleAddCountry = async () => {
    if (!selectedCountry || !selectedCountry.value) {
      toast.error("Please select a country.");
      return;
    }
   
    const payload = {
      countryId: null, // Make sure this value is correct
      isDefault: true,
      iso: selectedCountry.iso || "",
      attachedCountries: [
        { countryId: selectedCountry.value } // Corrected structure
      ],
    };
  
    try {
      await addCountryFromSelect(payload);

      fetchSortedCountries()

      setShowModal(false);
   
      setSelectedCountry(null);
    } catch (error) {
      console.error("Error adding country:", error);
    }
  };
  const handleDeleteCountry = async (id: string) => {
   
    try {
      await deleteCountryFromDragDrop(null, id);
  
      // Remove the country from the list after successful deletion
      setCountries((prev) => prev.filter((country) => country.id !== id));
  
      toast.success("Country deleted successfully");
    } catch (error) {
      console.error("Error deleting country:", error);
      toast.error("Failed to delete country");
    }
  };
  return (
    <Layout title="Plans">
      <div className="container mt-4">
        <div className="d-flex justify-content-end">
          <Button
            className="btn"
            style={{ backgroundColor: "#03FE9D", color: "#000", border: "none", outline: "none" }}
            onClick={() => setShowModal(true)}
          >
            ➕ Add Country
          </Button>
        </div>

      <div className={styles.design_heading}>
          <div className="d-flex align-items-center w-50">
          
          <span className="me-5">Flag</span>
          <span className="w-25">Country Name</span>
          <span className="ms-5">ISO</span>

          </div>
          <span className="pe-3">Action</span>
      </div>


        {countries && countries.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="countriesList">
            {(provided) => (
              <ul className="list-group mt-3" {...provided.droppableProps} ref={provided.innerRef}>
                {countries.map((country, index) => (
                  <Draggable key={country.id} draggableId={country.id} index={index}>
                    {(provided) => (
                      <li
                      
                        // className=""
                        
                        className={styles.design_demo}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="d-flex align-items-center w-50">
                          <img src={country.flag} alt={country.name} width="30" className="me-5" />
                          <span className="w-25">{country.name}</span>
                          <span className="ms-5">{country.iso}</span>
                        </div>
                        <Button
                          className="btn"
                          style={{ backgroundColor: "#03FE9D", color: "#000", border: "none" }}
                          onClick={() => handleDeleteCountry(country.id)}
                        >
                          Delete
                        </Button>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
  
  ) : (
    <p className="text-light">No countries available</p>
  )}
        {/* Modal for Adding a Country */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered className="border-0 shadow-none">
          <Modal.Header closeButton>
            <Modal.Title className="text-white">Select a Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              options={allCountries.map((c) => ({
                value: c.id,
                label: c.name,
                flag: c.flag ?? "",
              
              
              }))}
              formatOptionLabel={(country) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={country.flag || "https://via.placeholder.com/30"}
                    alt={country.label}
                    style={{ width: 20, height: 15, marginRight: 10 }}
                  />
                  {country.label}
                </div>
              )}
              value={selectedCountry}
              onChange={(selected) => setSelectedCountry(selected as CountryOption)}
              placeholder="Select a country"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              className="btn"
              style={{ backgroundColor: "#03FE9D", color: "#000", border: "none" }}
              onClick={handleAddCountry}
            >
              Add Country
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default DefaultCountries;
