import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAltSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Select from "react-select";

import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { UserTypes } from "../../../interfaces";
import { list, update } from "../api";
import Layout from "../../../ui/layout";
import { IMAGES } from "../../../assets/images";
import { DropDownMenu, ActionButton, Pagination, Popup } from "../../../ui";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const Users = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {   
    const roles: any = window.localStorage.getItem("admin_roles")
    if (roles == 4 || roles == 5) return navigate(APP_ROUTES.PARTNERS);
  },[])
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [search, setSearch] = useState<string | null>(null);
  const [order, setSortOrder] = useState<number>(0);
  const [sortBy, setSortColumn] = useState<string>("");
  const [blockedAccounts, setBlockedAccounts] = useState<boolean | null>(null);
  const [verifiedEmailAccount, setVerifiedEmailAccounts] = useState<boolean | null>(null);
  const [verifiedPhoneNoAccount, setVerifiedPhoneNoAccounts] = useState<boolean | null>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [users, setUsers] = useState<{ list: UserTypes.User[]; count: number }>({
    list: [],
    count: 0,
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [dialog, setDialog] = useState({ show: false, id: "", status: false });

  const listUsers = (
    pageIndex = pagination.skip,
    isBlocked: boolean | null = null,
    isPhoneNoVerified: boolean | null = null,
    isEmailVerified: boolean | null = null
  ) => {
    let query: any = {
      page: (1 + pageIndex),
      limit: pagination.limit,
      order,
      sortBy,
      isBlocked,
      isPhoneNoVerified,
      isEmailVerified,
    };

    if (search) {
      query['search'] = search;
    }
    if (startDate) {
      query.startDate = startDate;
    }
    if (endDate) {
      query.endDate = endDate;
    }
    list(query).then(({ data }) => {
      setUsers({ list: data.users, count: data.count });
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    const debounceSearch = setTimeout(() => {
      listUsers();
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [pagination ,startDate,endDate,search]);

  const blockUnblockUser = () => {
    const payload = {
      userId: dialog.id,
      isBlocked: dialog.status,
    };
    toast.promise(
      update(payload),
      {
        pending: {
          render() {
            return LOCAL_CONSTANTS.updatingUser;
          },
        },
        success: {
          render() {
            let tempUser = users.list;
            let index = tempUser.findIndex((item) => item._id === dialog.id);

            tempUser[index].isBlocked = !tempUser[index].isBlocked;

            setUsers({ ...users, list: tempUser });

            setDialog({ show: false, id: "", status: false });
            return dialog.status
              ? LOCAL_CONSTANTS.userBlockedSuccessfully
              : LOCAL_CONSTANTS.userUnblockedSuccessfully;
          },
        },
        error: {
          render() {
            return LOCAL_CONSTANTS.errorOnUpdatingUser;
          },
        },
      });
  };

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(
      order === LOCAL_CONSTANTS.descKey
        ? LOCAL_CONSTANTS.ascKey
        : LOCAL_CONSTANTS.descKey
    );
    const query = {
      ...pagination,
      search,
      order:
        order === LOCAL_CONSTANTS.descKey
          ? LOCAL_CONSTANTS.ascKey
          : LOCAL_CONSTANTS.descKey,
      sortBy: column,
    };
    list(query).then(({ data }) => {
      setUsers({ list: data.users, count: data.count });
      setLoading(false);
    });
  };

  const renderSortIcon = (column: string) => {
    if (sortBy === column) {
      return order === LOCAL_CONSTANTS.descKey ? " ▼" : " ▲";
    }
    return null;
  };
  const handleResetFilters = () => {
    setSearch("");
    setVerifiedEmailAccounts(null);
    setVerifiedPhoneNoAccounts(null);
    setStartDate("");
    setEndDate("");
    setBlockedAccounts(null);
    setPagination({
      skip: 0,
      limit: LOCAL_CONSTANTS.limitCount,
    });
    setTimeout(() => {
      setSearch("");
    }, 0);
  };
  return (
    <Layout title="Users">
      <div className={commonStyles.tableData}>
        <div className={commonStyles.topFilter}>
        <div className={styles.date_calender}>
          <input
            type="search"
            placeholder="Search by name or email"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search || ""}
            // onKeyDown={(event) => {
            //   if (event.keyCode === 13) {
            //     setLoading(true);
            //     const debounceSearch = setTimeout(() => {
            //       listUsers();
            //     }, 500);
            //     return () => {
            //       clearTimeout(debounceSearch);
            //     };
            //   };
            // }}
          />
          </div>
      <div className={styles.date_calender}>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        placeholder="Start Date"
      />
    </div>
    <div className={styles.date_calender}>
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        placeholder="End Date"
      />
    </div>
          <div className={`d-flex gap-3 align-items-center w-50 ${styles.filterSlect}`}>
    
            <div className="w-100">
              <p className="mb-0 text-white text-center">Account Verification</p>
              <Select
                options={LOCAL_CONSTANTS.blockedAccounts}
                onChange={(response) => {
                  if (response) {
                    let pageIndex = 0;
                    let isBlocked = response.value;

                    listUsers(pageIndex, isBlocked, verifiedPhoneNoAccount, verifiedEmailAccount);
                    setBlockedAccounts(isBlocked);
                  }
                }}
                defaultValue={LOCAL_CONSTANTS.emailVerified[0]}
                className="w-100"
              />
            </div>

            <div className="w-100">
              <p className="mb-0 text-white text-center">Mobile No Verification</p>
              <Select
                options={LOCAL_CONSTANTS.phoneNoVerified}
                onChange={(response) => {
                  if (response) {
                    let pageIndex = 0;
                    let status = response.value;

                    setVerifiedPhoneNoAccounts(status);
                    listUsers(pageIndex, blockedAccounts, status, verifiedEmailAccount);
                  }
                }}
                defaultValue={LOCAL_CONSTANTS.emailVerified[0]}
                className="w-100"
              />
            </div>

            <div className="w-100">
              <p className="mb-0 text-white text-center">Email Verification</p>
              <Select
                options={LOCAL_CONSTANTS.emailVerified}
                onChange={(response) => {
                  if (response) {
                    let pageIndex = 0;
                    let status = response.value;

                    setVerifiedEmailAccounts(status);
                    listUsers(pageIndex, blockedAccounts, verifiedPhoneNoAccount, status);
                  }
                }}
                defaultValue={LOCAL_CONSTANTS.emailVerified[0]}
                className="w-100"
              />
            </div>
            <button
            onClick={handleResetFilters}
            className={`mt-4 ${styles.loyalityPointBtn}`}
          >
            Reset 
          </button>
          </div>
        </div>
        <div className={styles.scrollTable}>
          <table>
            <thead>
              <tr>
                {LOCAL_CONSTANTS.usersTableHeaders?.map((header: UserTypes.TableHeader) => (
                  <th
                    className={`${["fullName", "phoneNumber"].includes(header?.value)
                      ? "cursor-pointer"
                      : ""
                      }`}
                    onClick={() => {
                      if (["fullName", "phoneNumber"].includes(header?.value)) {
                        handleSort(header?.value);
                      }
                    }}
                    key={header?.value}
                  >
                    {header?.label}
                    {sortBy === header?.value &&
                      ["fullName", "phoneNumber"].includes(header?.value) &&
                      renderSortIcon(header?.value)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={7} align="center" className={styles.loadingText}>
                    {LOCAL_CONSTANTS.loading}
                  </td>
                </tr>
              )}
              {!loading && users.list.length === 0 && (
                <tr>
                  <td colSpan={7} align="center">
                    No User Found
                  </td>
                </tr>
              )}
              {!loading &&
                users.list.map((item) => (
                  <tr key={item._id}>
                    <td>
                      <img
                        width={10}
                        height={10}
                        src={
                          item.avatar && item.avatar.length
                            ? item.avatar
                            : IMAGES.RegisteredUserImage
                        }
                        alt="userImage"
                      />
                      {item.fullName||"N/A"}
                    </td>
                    <td>
                      {item.countryCode} {item.phoneNumber ||"N/A"}
                    </td>

                    <td>{item.email||"N/A"}</td>
                    <td>
                      {moment.utc(item.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      {item.isEmailVerified
                        ? LOCAL_CONSTANTS.yes
                        : LOCAL_CONSTANTS.no}
                    </td>
                    <td>
                      {item.isPhoneNoVerified
                        ? LOCAL_CONSTANTS.yes
                        : LOCAL_CONSTANTS.no}
                    </td>
                    <td>
                      {item.isBlocked ? LOCAL_CONSTANTS.yes : LOCAL_CONSTANTS.no}
                    </td>
                    <td>
                      <Dropdown>
                        <div className="cursor-pointer">
                          <Dropdown.Toggle as={ActionButton}></Dropdown.Toggle>
                        </div>
                        <Dropdown.Menu as={DropDownMenu}>
                          <Dropdown.Item
                            onClick={() =>
                              setDialog({
                                show: true,
                                id: item._id,
                                status: !item.isBlocked,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faUserAltSlash}
                            />
                            {LOCAL_CONSTANTS.accountStatus}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={users.count}
        />
        <Popup
          show={dialog.show}
          handleClose={() => setDialog({ show: false, id: "", status: false })}
          heading={LOCAL_CONSTANTS.areYouSure}
          content={LOCAL_CONSTANTS.areYouSureRestrictUserAccess}
          handleSubmit={blockUnblockUser}
          id={dialog.id}
        />
      </div>
    </Layout>
  );
};

export default Users;
