export const APP_ROUTES = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  ADMIN: "/admin",
  CMS: "/cms",
  PROFILE: "/profile",
  ORDER: "/orders",
  VIEW_DOCUMENTS: '/document/:id',
  PROMOS: "/promos",
  COUPONS: "/coupons",
  NEW_PROMO: "/promos/new",
  UPDATE_PROMO: "/promos/:id",
  NEW_COUPON: "/coupons/new",
  UPDATE_COUPON: '/coupons/:id',
  NOTIFICATIONS: "/notification",
  LOYALITY_POINTS: "/loyalityPoints",
  LOYALITY_POINTS_SETTINGS: "/orders/loyalityPoints",
  BLOGS: "/blogs",
  UPDATE_BLOG: "/blogs/:id/edit",
  SPECIFIC_BLOG: '/blogs/:id',
  NEW_BLOG: "/blogs/new",
  PLANS: "/plans",
  SANDBOX_PLANS: "/sandbox-plans",
  REGIONS_PLANS: "/plans/regions/:id",
  COUNTRY_PLANS: "/plans/countries/:id",
  SANDBOX_COUNTRY_PLANS: "/sandbox-plans/countries/:id",
  PLANS_SETTINGS: "/plans/settings",
  ANALYTICS: "/analytics",
  EVENT_USERS: "/event-users",
  SEO_USERS: "/seo/:id",
  PARTNERS: "/partners",
  PARTNERS_USER: "/partners/:partnerName/:partnerWebsiteId",
  PARTNERS_USER_ORDER_LIST: "/partners/:userId/orders-list",
  VSF_EMPLOYEE_QR: "/partners/:partnerName/:partnerWebsiteId/generate_employee_qr_code",
  VSF_EMPLOYEES_LIST: "/partners/:partnerName/:partnerWebsiteId/employees_list",
  PARTNER_ADMIN_CREATE_FORM: "/partners/:partnerName/:partnerWebsiteId/create-partner-admin",
  PARTNER_ADMIN_LIST: "/partners/:partnerName/:partnerWebsiteId/partner-admin-list",
  PARTNER_UPSELL_RECORDS: "/partners/:partnerName/:partnerWebsiteId/upsell-records",
  CONFIGRUATIONS:"/configurations",
  geofenced : '/geofenced',
  geofencedPLANS : '/geofenced/:geoName/esim-plans-list',
  geofencedREGIONS : '/geofenced/:geoName/regions-list',
  geofencedCOUNTRIES : '/geofenced/:geoName/countrie-list',
  DEFAULT_COUNTRIES:'defaultCountries',
  PARTNER_ESIM_PLAN_LIST: "/partners/:partnerName/:partnerWebsiteId/plans_list",
  INDIVIDUAL_ADMIN: "/individual-from",
  PARTNER_ESIM_NIYO_PLAN: "/partners/:partnerName/:partnerWebsiteId/plans",
  NIYO_COUNTRY_PLANS: "/partners/:partnerName/:partnerWebsiteId/plans/countries/:id",
  SECRET_KEY_CONFIGURATION: "/partners/secret_key_configuration",
  OFFERS_FOR_YOU : "/offers-for-you"
};
