import { useParams } from 'react-router-dom';
import styles from '../styles/style.module.css';
import { Layout } from '../../../ui';
import { useEffect, useState } from 'react';
import { getDocumentsData } from '../api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';



function Document() {
    const params = useParams();
    const [getSingleOrder, setGetSingleOrder] = useState < any > ()

    useEffect(() => {

        getDocumentsData(params?.id).then((res) => {
            setGetSingleOrder(res?.data)
        }).catch((error) => {
            toast.error(error?.data?.message || "Something went wrong!")
        })
    }, [])

    const handleImageView = (imageUrl: string) => {
        const viewer = window.open("", "Image Viewer", "width=800,height=600");
        if (viewer) {
            viewer.document.write(`
                <html>
                <head>
                    <title>Image Viewer</title>
                    <style>
                        body { margin: 0; display: flex; align-items: center; justify-content: center; height: 100vh; background: black; }
                        img { max-width: 100%; max-height: 100%; }
                    </style>
                </head>
                <body>
                    <img src="${imageUrl}" alt="Full View" />
                </body>
                </html>
            `);
        } else {
            toast.error("Popup blocked! Please allow popups for this site.");
        }
    };

    const handleImageDownload = (imageUrl: string) => {
        try {
            const link = document.createElement('a');
            link.href = imageUrl;
            const filename = imageUrl.split('/').pop() || 'download.jpg';
            link.download = filename.split('?')[0];
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            toast.error('Failed to download image');
        }
    };

    return (
        <Layout title="Document">
            {/* {loader && <MainLoader />} */}
            <div className={styles.uploadDoc}>
                <div className='container'>
                    <h3>Upload Documents</h3>
                    <div className={styles.outerUpoad}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className={styles.innerUpload}>
                                    <h5>Passport</h5>
                                    <h6>Upload Passport Images </h6>
                                    <p>Upload a clear picture of your Passport</p>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className={styles.uploadFrame}>
                                                {
                                                    <div className={styles.imageContainer}>
                                                        <img
                                                            src={getSingleOrder?.documents?.passportFront}
                                                            alt="passport_front"
                                                        />
                                                        <div className={styles.imageActions}>
                                                            <FontAwesomeIcon
                                                                icon={faEye}
                                                                onClick={() => handleImageView(getSingleOrder?.documents?.passportFront)}
                                                                className={styles.actionIcon}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                onClick={() => handleImageDownload(getSingleOrder?.documents?.passportFront)}
                                                                className={styles.actionIcon}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <span className={styles.tagName}>Front</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className={styles.uploadFrame}>
                                                {
                                                    <div className={styles.imageContainer}>
                                                        <img
                                                            src={getSingleOrder?.documents?.passportBack}
                                                            alt='passport_back' />
                                                        <div className={styles.imageActions}>
                                                            <FontAwesomeIcon
                                                                icon={faEye}
                                                                // onClick={() => handleImageView(getSingleOrder?.documents?.passportBack)}
                                                                className={styles.actionIcon}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                onClick={() => handleImageDownload(getSingleOrder?.documents?.passportBack)}
                                                                className={styles.actionIcon}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                            <span className={styles.tagName}>Back</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className={styles.innerUpload}>
                                    <h5>Visa/Ticket</h5>
                                    <h6>Upload VISA/TICKET Images  </h6>
                                    <p>Upload a clear picture of VISA/TICKET</p>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className={styles.uploadFrame}>
                                                {
                                                    <div className={styles.imageContainer}>
                                                        <img
                                                            src={getSingleOrder?.documents?.visa}
                                                            alt='visa' />
                                                        <div className={styles.imageActions}>
                                                            <FontAwesomeIcon
                                                                icon={faEye}
                                                                // onClick={() => handleImageView(getSingleOrder?.documents?.visa)}
                                                                className={styles.actionIcon}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faDownload}
                                                                onClick={() => handleImageDownload(getSingleOrder?.documents?.visa)}
                                                                className={styles.actionIcon}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                {/* <img src={blogone} alt=""/> */}
                                            </div>
                                            <span className={styles.tagName}>Front</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Document