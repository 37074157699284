import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import styles from "../styles/list.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { OrderTypes, UserTypes } from "../../../interfaces";
import { Layout, Pagination } from "../../../ui";
import { list, partnerWebsitesList } from "../api";
import Select from "react-select";
import { toast } from "react-toastify";
import ModifyESim from "./modifyESim";
export const ORDER_STATUS: Record<string, number> = {
  PENDING: 1,
  SUCCESS: 2,
  FAILED: 3
};
const Orders = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [search, setSearch] = useState<string>("");
  const [partenList, setPartenList] = useState<any>([]);
  const [partenListVal, setPartenListVal] = useState<any>("");
  const [partnerWebsiteId, setPartnerWebsiteId] = useState<any>("");
  const [order, setSortOrder] = useState<number>();
  const [sortBy, setSortColumn] = useState<string>("");
  const [payments, setOrdersList] = useState<{
    list: OrderTypes.Payment[];
    count: number;
  }>({
    list: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [filterOrderRangeStartDate, setFilterOrderRangeStartDate] = useState<string>("");
  const [filterOrderRangeEndDate, setFilterOrderRangeEndDate] = useState<string>("");
  
  const [activeTab, setActiveTab] = useState<Number>(1);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);

  const listOrders = () => {
    const query: any = {
      page: 1 + pagination.skip,
      limit: pagination.limit,
      search,
      order,
      // sortBy,
    };
    if (partnerWebsiteId) {
      query.partnerWebsiteId = partnerWebsiteId;
    }
    if (selectedStatus) {
      query.orderStatus = selectedStatus.value; // Filter by selected status
    }
    if (filterOrderRangeStartDate) {
      query.filterOrderRangeStartDate = filterOrderRangeStartDate;
    }
    if (filterOrderRangeEndDate) {
      query.filterOrderRangeEndDate = filterOrderRangeEndDate;
    }
    
    console.log("API query:", query); 
    list(query).then(({ data }) => {
      setOrdersList({ list: data?.orders, count: data?.count });
      console.log(data.orders)
      setLoading(false);
    });
  };

  useEffect(() => {
    partnerWebsitesList()
      .then((res) => {
        setPartenList(res?.data);
      })
      .catch((error) => {
        toast.error(error?.data?.message || "Something went wrong!");
      });
  }, []); // Empty dependency array means this runs once on mount

  // Search debounce effect
  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      if (search !== null) { // Only call when search state changes intentionally
        listOrders();
      }
    }, 500);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [search]);
  const partenListOptions = partenList.map((parten: any) => ({
    value: parten._id,
    label: parten?.websiteUrl,
  }));

  useEffect(() => {
    setLoading(true);
    listOrders();
  }, [pagination.skip, partnerWebsiteId,selectedStatus,filterOrderRangeStartDate, filterOrderRangeEndDate]);

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(
      order === LOCAL_CONSTANTS.descKey
        ? LOCAL_CONSTANTS.ascKey
        : LOCAL_CONSTANTS.descKey
    );
    const query = {
      ...pagination,
      search,
      order:
        order === LOCAL_CONSTANTS.descKey
          ? LOCAL_CONSTANTS.ascKey
          : LOCAL_CONSTANTS.descKey,
      sortBy: column,
    };
    list(query).then(({ data }) => {
      setOrdersList({ list: data?.orders, count: data?.count });
      setLoading(false);
    });
  };
  const renderSortIcon = (column: string) => {
    if (sortBy === column) {
      return order === LOCAL_CONSTANTS.descKey ? " ▼" : " ▲";
    }
    return null;
  };
  const orderStatusOptions = Object.keys(ORDER_STATUS).map((key) => ({
    value: ORDER_STATUS[key as keyof typeof ORDER_STATUS],  // Ensure safe access
    label: key.charAt(0) + key.slice(1).toLowerCase(),  // Converts to label: "Pending", "Success", "Failed"
  }));
  const handleStatusChange = (selectedOption: any) => {
    setSelectedStatus(selectedOption);
  };
  const handleResetFilters = () => {
    setSearch("null");
    setPartnerWebsiteId("");
    setPartenListVal("");
    setFilterOrderRangeStartDate("");
    setFilterOrderRangeEndDate("");
    setSelectedStatus(null);
    setPagination({
      skip: 0,
      limit: LOCAL_CONSTANTS.limitCount,
    });
    setTimeout(() => {
      setSearch("");
    }, 0);
  };

  return (
    <Layout title="Orders">
      <div className={commonStyles.tableData}>
        <div className={styles.mainTabbing}>
          <div className="leftTabbing">
            <div className={styles.topCms}>
              <ul>
                <li
                  className={activeTab === 1 ? styles.active : ""}
                  onClick={() => {
                    setSearch("");
                    setActiveTab(1);
                  }}
                >
                  <p>Online Orders</p>
                </li>
                <li
                  className={activeTab === 2 ? styles.active : ""}
                  onClick={() => {
                    setSearch("");
                    setActiveTab(2);
                  }}
                >
                  <p>Manual Orders</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={commonStyles.topFilter}>
          {activeTab == 1 && (
            <>
              <div className="">
                <Select
                  options={[
                    { value: "", label: "Select option" },
                    ...partenListOptions,
                  ]}
                  onChange={(data: any) => {
                    setPartnerWebsiteId(data?.value);
                  }}
                  value={partenListOptions.find(
                    (option: any) => option.value === partenListVal
                  )}
                  placeholder="Select Partner Website"
                />
              </div>
              
            </>
          )}
            <div className="">
            <Select
              options={orderStatusOptions}
              value={selectedStatus}
              onChange={handleStatusChange}
              placeholder="Select Order Status"
            />
          </div>
          <div className={styles.date_calender}>
          <input
          className="text-white "
            type="search"
            placeholder="Search by name or email"
            value={search || ""}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          </div>
          <div className={styles.date_calender}>
      <input
        type="date"
        value={filterOrderRangeStartDate}
        onChange={(e) => setFilterOrderRangeStartDate(e.target.value)}
        placeholder="Start Date"
      />
    </div>
    <div className={styles.date_calender}>
      <input
        type="date"
        value={filterOrderRangeEndDate}
        onChange={(e) => setFilterOrderRangeEndDate(e.target.value)}
        placeholder="End Date"
      />
    </div>
    <button
                onClick={() => navigate(APP_ROUTES.LOYALITY_POINTS_SETTINGS)}
                className={styles.loyalityPointBtn}
              >
                Edit Loyality Point
              </button>
              {activeTab == 2 && (
            <button
              onClick={() => navigate(APP_ROUTES.INDIVIDUAL_ADMIN)}
              className={styles.loyalityPointBtn}
            >
              Add Individual Order
            </button>
          )}
          <button
            onClick={handleResetFilters}
            className={styles.loyalityPointBtn}
          >
            Reset 
          </button>
         
        </div>
        <div className={styles.scrollTable}>
          <table>
            <thead>
              <tr>
                {LOCAL_CONSTANTS.paymentsTableHeaders?.map(
                  (header: UserTypes.TableHeader) => (
                    <th
                      className={`${
                        ["user.fullName", "bundle.name", "price"].includes(
                          header?.value
                        )
                          ? "cursor-pointer"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          ["user.fullName", "bundle.name", "price"].includes(
                            header?.value
                          )
                        ) {
                          handleSort(header?.value);
                        }
                      }}
                      key={header?.value}
                    >
                      {header?.label}
                      {sortBy === header?.value &&
                        ["user.fullName", "bundle.name", "price"].includes(
                          header?.value
                        ) &&
                        renderSortIcon(header?.value)}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={8} align="center">
                    {LOCAL_CONSTANTS.loading}
                  </td>
                </tr>
              )}
              {!loading && payments.list.length === 0 && (
                <tr>
                  <td colSpan={8} align="center">
                    No Order Found
                  </td>
                </tr>
              )}
              {!loading &&
                payments?.list?.map((item: any) => (
                  <tr key={item._id}>
                    <td>{`${item.user?.fullName || LOCAL_CONSTANTS.na} ${
                      item?.partnerWebsiteName
                        ? "( from " +
                          (item?.partnerWebsiteName).split(".")[0] +
                          " )"
                        : ""
                    }`}</td>
                    <td>{`${item.user?.email || LOCAL_CONSTANTS.na}`}</td>
                    <td>{item.bundle?.name||"N/A"}</td>
                    <td>
                      {moment.utc(item.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      {
                        LOCAL_CONSTANTS.paymentOrderStatus?.find(
                          (elt: OrderTypes.StatusEnums) =>
                            elt.value === item.orderStatus
                        )?.label
                      }
                    </td>
                    <td>
                      {
                        LOCAL_CONSTANTS.paymentOrderStatus?.find(
                          (elt: OrderTypes.StatusEnums) =>
                            elt.value === item.paymentStatus
                        )?.label
                      }
                    </td>
                    <td>{item.priceCurrency ||"N/A"}</td>
                    <td>{item.price.toFixed(2)||"N/A"}</td>
                    <td>{item.quantity||"N/A"}</td>
                    {/* {item?.documents &&  */}
                    <td className="d-flex gap-2">
                     {/* {partnerWebsiteId=== "667000f74e37cd362882386e" && <ModifyESim />}  */}
                      <button
                        className={styles.loyalityPointBtn}
                        onClick={() => navigate(`/document/${item._id}`)}
                      >
                        View
                      </button>
                    </td>
                    {/* } */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={payments.count}
        />
      </div>
    </Layout>
  );
};

export default Orders;
