import { useEffect, useLayoutEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import {
  getVFSPhilpCoupons,
  list,
  ordersList,
  physicalEsim,
  VfsCountryList,
  VfsGroupFinalNameList,
  VfsLocationList,
} from "../api";
import Layout from "../../../ui/layout";
import { Pagination } from "../../../ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserList from "./userList";
import Orders from "../../orders/routes/list";
import OrderList from "./orderList";
import { exportToExcelInChunks } from "../../../utils/excel.export";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import PartnersConfigration from "./configrations";
import VFSPhilipCouponList from "./vfsPhilipCouponList";
import WeeklyESIMChart from "./WeeklyESIMChart";

const UsersList = () => {
  const roles = window.localStorage.getItem("admin_roles");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { partnerName, partnerWebsiteId } = useParams();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  // const [activeTab, setActiveTab] = useState<Number>(1);
  // Set initial state of activeTab
  const [activeTab, setActiveTab] = useState(Number(roles) === 5 ? 9 : 1); // Default to tab 9 if roles is 5

  const [activeOrderTab, setActiveOrderTab] = useState(false);
  const [niyoTabbing, setNiyoTabbing] = useState(0);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState({
    startDate: "",
    endDate: "",
    submit: false,
  });

  const [refreshData, setRefreshData] = useState(false);

  const [activeData, setActiveData] = useState<any>({
    list: [],
    count: 0,
  });

  const [selectData, setSelectData] = useState<any>({
    combinedFinalName: [],
    missionServed: [],
  });
  const [selectDataOption, setSelectDataOption] = useState<any>({
    countryName: "",
    combinedFinalName: "",
  });

  const [loading, setLoading] = useState<boolean>(true);

  const routAccess =
    (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) ||
    (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles)) ||
    (roles !== null &&
      LOCAL_CONSTANTS.ROLES.TRAVELTHRU_USER === Number(roles)) ||
    (roles !== null && LOCAL_CONSTANTS.ROLES.GLOBALTIX === Number(roles));
  const [paymentStatus, setPaymentStatus] = useState("");
  useLayoutEffect(() => {
    if (
      partnerName?.toLocaleLowerCase() === LOCAL_CONSTANTS.PARTNERS.VFSPHILLI
    ) {
      setActiveTab(7);
    } else if (
      partnerName?.toLocaleLowerCase() === LOCAL_CONSTANTS.PARTNERS.VFS
    ) {
      setActiveTab(2);
    } else if (
      partnerName?.toLocaleLowerCase() === LOCAL_CONSTANTS.PARTNERS.NIYO
    ) {
      setActiveTab(3);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const query: any = {
      paymentProof: paymentStatus,
      partnerWebsiteId: partnerWebsiteId,
      page: 1 + pagination.skip,
      limit: pagination.limit,
      search: search,
    };
    if (activeTab == 1) {
      query.searchString = search;
      list(query).then((res: any) => {
        setLoading(false);
        setActiveData({ list: res?.data?.users, count: res?.data?.count });
      });
    }
    if (activeTab == 2) {
      if (selectDataOption.countryName)
        query.countryName = selectDataOption.countryName;
      if (selectDataOption.combinedFinalName)
        query.missionServed = selectDataOption.combinedFinalName;

      physicalEsim(query).then((res: any) => {
        setLoading(false);
        setActiveData({ list: res?.data?.users, count: res?.data?.count });
      });
      const locationQuery = {
        partnerWebsiteId: partnerWebsiteId,
        page: 1 + pagination.skip,
        limit: 1000,
      };
      VfsCountryList(locationQuery)
        .then((res: any) => {
          setLoading(false);
          res?.data?.map((item: any) => {
            setSelectData((prev: any) => ({
              ...prev,
              missionServed: [
                ...prev.missionServed,
                {
                  value: item._id,
                  label: item._id,
                },
              ],
            }));
          });
        })
        .catch((error) => {
          setLoading(false);
        });

      VfsGroupFinalNameList(locationQuery)
        .then((res: any) => {
          setLoading(false);
          res?.data?.map((item: any) => {
            setSelectData((prev: any) => ({
              ...prev,
              combinedFinalName: [
                ...prev.combinedFinalName,
                {
                  value: item._id,
                  label: item._id,
                },
              ],
            }));
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    if (activeTab == 3) {
      query.isFree = activeOrderTab;
      query.search = search;
      query.filterOrderRangeStartDate = dateFilter?.startDate;
      query.filterOrderRangeEndDate = dateFilter?.endDate;
      ordersList(query)
        .then((res: any) => {
          setLoading(false);
          setActiveData({ list: res?.data?.orders, count: res?.data?.count });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    if (activeTab === 7 || activeTab === 8) {
      const payload: any = {
        page: 1 + pagination.skip,
        limit: pagination.limit,
      };

      activeTab === 7 ? (payload.status = 1) : (payload.status = 2);

      getVFSPhilpCoupons(payload)
        .then((res: any) => {
          setLoading(false);
          setActiveData({ list: res?.data, count: res?.count });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [
    refreshData,
    pagination,
    activeTab,
    activeOrderTab,
    search,
    dateFilter.submit === true,
    selectDataOption.countryName,
    selectDataOption.combinedFinalName,
    paymentStatus,
  ]);

  const handleExcelExport = () => {
    const query: any = {
      partnerWebsiteId: partnerWebsiteId,
      page: 1,
      limit: activeData.count,
      isFree: activeOrderTab,
      search: search,
    };
    const customHeader = [
      "Order ID",
      "Name",
      "Email",
      "Phone Number",
      "Travelling Country",
      "Order Date",
      "Order Status",
      "Travelling Date",
      "Email Sent",
    ];
    const orderData = [];
    ordersList(query)
      .then((res: any) => {
        const getData = res?.data?.orders?.map((order: any) => ({
          orderId: order.orderId,
          fullName: order.user.fullName ? order.user.fullName : "N/A",
          email: order.user.email,
          phoneNumber: order.user.phoneNumber ? order.user.phoneNumber : "N/A",
          travellingCountry: order.bundle.name,
          orderDate: moment(order?.createdAt).format(
            LOCAL_CONSTANTS.dateFormat
          ),
          orderStatus: order.orderStatus == 1 ? "Pending" : "Success",
          travellingDate: order.user.travellingDate
            ? moment(order.user.travellingDate).format(
                LOCAL_CONSTANTS.dateFormat
              )
            : "N/A",
          freeEsimQrEmailSent: order.freeEsimQrEmailSent
            ? "Success"
            : "Pending",
        }));
        if (getData?.length == 0) return toast.error("No records found!");
        exportToExcelInChunks(getData, "niyo_orders", 300, customHeader);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: any) => {
    e.preventDefault();
    if (!dateFilter?.startDate) return toast.error("Start date is required!");
    if (!dateFilter?.endDate) return toast.error("End date is required!");
    if (dateFilter?.startDate > dateFilter?.endDate)
      return toast.error(
        "End date shuold be grater then or equal from start date"
      );
    setDateFilter({ ...dateFilter, submit: true });
  };
  const handleChange = (e: any, name: string) => {
    if (name == "combinedFinalName")
      setSelectDataOption((prev: any) => ({
        ...prev,
        combinedFinalName: e.value,
      }));

    if (name == "countryName")
      setSelectDataOption((prev: any) => ({
        ...prev,
        countryName: e.value,
      }));
  };
  const options = [
    { value: "", label: "All" },
    { value: "free", label: "Free" },
    { value: "paid", label: "Paid" },
  ];

  const handleChinaExport = () => {
    setLoading(true);
    const query: any = {
      partnerWebsiteId: partnerWebsiteId,
      page: 1,
      limit: activeData.count,
    };
    if (selectDataOption.countryName)
      query.countryName = selectDataOption.countryName;
    if (selectDataOption.combinedFinalName)
      query.missionServed = selectDataOption.combinedFinalName;

    const customHeader = [
      "Name",
      "Email",
      "Phone Number",
      "Country Name",
      "Mission Served",
      "Created Date",
    ];

    physicalEsim(query)
      .then((res: any) => {
        const getData = res?.data?.users?.map((user: any) => ({
          fullName: user.fullName || "N/A",
          email: user.email || "N/A",
          phoneNumber: user.phoneNumber || "N/A",
          countryName: user.countryName || "N/A",
          missionServed: user.missionServed || "N/A",
          createdAt: moment(user.createdAt).format(LOCAL_CONSTANTS.dateFormat),
        }));

        if (!getData?.length) {
          toast.error("No records found!");
          setLoading(false);
          return;
        }

        exportToExcelInChunks(getData, "china_users", 300, customHeader);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Export failed!");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (Number(roles) === 5) {
      setActiveTab(9); // Set to 9 when roles === 5 (Order Analytics tab)
    }
  }, [roles]);

  return (
    <Layout title={`${partnerName?.toUpperCase()}`}>
      <div className={commonStyles.tableData}>
        <div className={styles.mainTabbing}>
          <div className="leftTabbing">
            <div className={styles.topCms}>
              <ul>
                {Number(roles) !== 5 &&
                  partnerName?.toLocaleLowerCase() ===
                    LOCAL_CONSTANTS.PARTNERS.VFS && (
                    <li
                      className={activeTab === 2 ? styles.active : ""}
                      onClick={() => {
                        setSearch("");
                        setActiveTab(2);
                      }}
                    >
                      <p>Physical SIM Users (Chinese)</p>
                    </li>
                  )}

                {Number(roles) !== 5 && LOCAL_CONSTANTS.PARTNERS.NIYO && (
                  <li
                    className={`${activeTab === 3 ? styles.active : ""} ${
                      Number(roles) === 5 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      setSearch("");
                      setActiveTab(3);
                      setNiyoTabbing(0);
                    }}
                  >
                    <p>Orders</p>
                  </li>
                )}

                {roles !== null &&
                  LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) &&
                  partnerName?.toLocaleLowerCase() ===
                    LOCAL_CONSTANTS.PARTNERS.NIYO && (
                    <>
                      <li
                        className={activeTab === 4 ? styles.active : ""}
                        onClick={() => {
                          setSearch("");
                          setActiveTab(4);
                          setNiyoTabbing(0);
                        }}
                      >
                        <p>configuration</p>
                      </li>
                      <li
                        className={activeTab === 5 ? styles.active : ""}
                        onClick={() => {
                          setSearch("");
                          setActiveTab(5);
                          setNiyoTabbing(0);
                          navigate(
                            `/partners/${partnerName}/${partnerWebsiteId}/plans_list`
                          );
                        }}
                      >
                        <p>eSIM configuration</p>
                      </li>
                    </>
                  )}

                {roles !== null &&
                  LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) &&
                  partnerName?.toLocaleLowerCase() !==
                    LOCAL_CONSTANTS.PARTNERS.VFSPHILLI && (
                    <li
                      className={activeTab === 6 ? styles.active : ""}
                      onClick={() => {
                        setSearch("");
                        setActiveTab(6);
                        setNiyoTabbing(0);
                        navigate(
                          `/partners/${partnerName}/${partnerWebsiteId}/plans`
                        );
                      }}
                    >
                      <p>API</p>
                    </li>
                  )}

                {partnerName?.toLocaleLowerCase() ===
                  LOCAL_CONSTANTS.PARTNERS.VFSPHILLI && (
                  <>
                    <li
                      className={activeTab === 7 ? styles.active : ""}
                      onClick={() => {
                        setSearch("");
                        setActiveTab(7);
                        setNiyoTabbing(0);
                      }}
                    >
                      <p>Active Coupons</p>
                    </li>
                    <li
                      className={activeTab === 8 ? styles.active : ""}
                      onClick={() => {
                        setSearch("");
                        setActiveTab(8);
                        setNiyoTabbing(0);
                      }}
                    >
                      <p>Used Coupons</p>
                    </li>
                  </>
                )}

                {/* Order Analytics Tab */}
                {Number(roles) === 5 &&
                  partnerName?.toLocaleLowerCase() ===
                    LOCAL_CONSTANTS.PARTNERS.VFS && (
                    <li
                      className={activeTab === 9 ? styles.active : ""}
                      onClick={() => {
                        setSearch(""); // Reset the search
                        setActiveTab(9); // Set the active tab to 9 (Order Analytics tab)
                      }}
                    >
                      <p>Order Analytics</p>
                    </li>
                  )}
              </ul>
            </div>
          </div>
          <div className="rightTabbing">
            {!routAccess && (
              <button
                className={`mx-2 ${styles.loyalityPointBtn}`}
                onClick={() =>
                  navigate(
                    `/partners/${partnerName}/${partnerWebsiteId}/partner-admin-list`
                  )
                }
              >
                Admin List
              </button>
            )}
            {Number(roles) !== 5 &&
              partnerName?.toLocaleLowerCase() ==
                LOCAL_CONSTANTS.PARTNERS.VFS && (
                <>
                  <button
                    className={`mx-2 ${styles.loyalityPointBtn}`}
                    onClick={() =>
                      navigate(
                        `/partners/${partnerName}/${partnerWebsiteId}/generate_employee_qr_code`
                      )
                    }
                  >
                    Generate qr code for Location{" "}
                  </button>
                  <button
                    className={`mx-2 ${styles.loyalityPointBtn}`}
                    onClick={() =>
                      navigate(
                        `/partners/${partnerName}/${partnerWebsiteId}/employees_list`
                      )
                    }
                  >
                    Location List{" "}
                  </button>
                </>
              )}

            {partnerName?.toLocaleLowerCase() ==
              LOCAL_CONSTANTS.PARTNERS.VFS && (
              <>
                {activeTab === 2 && (
                  <div className="d-flex">
                    <Select
                      options={selectData?.combinedFinalName}
                      onChange={(e) => handleChange(e, "combinedFinalName")}
                      isSearchable
                      className="mx-3"
                      placeholder="Select Combined Final Name"
                    />
                    <Select
                      options={selectData?.missionServed}
                      onChange={(e) => handleChange(e, "countryName")}
                      isSearchable
                      className="mx-3"
                      placeholder="Select Country"
                    />
                    <Select
                      isSearchable
                      placeholder="Payment Status"
                      options={options}
                      className="mx-3"
                      onChange={(option) =>
                        setPaymentStatus(option?.value || "")
                      }
                      value={options.find((opt) => opt.value === paymentStatus)}
                    />
                  </div>
                )}
              </>
            )}

            {partnerName?.toLocaleLowerCase() ==
              LOCAL_CONSTANTS.PARTNERS.NIYO &&
              activeTab === 2 && (
                <>
                  <div className={styles.topCms}>
                    <ul>
                      <li
                        className={niyoTabbing === 1 ? styles.active : ""}
                        onClick={() => setNiyoTabbing(1)}
                      >
                        <p>Niyo</p>
                      </li>
                      <li
                        className={niyoTabbing === 2 ? styles.active : ""}
                        onClick={() => setNiyoTabbing(2)}
                      >
                        <p>Niyo Basic</p>
                      </li>
                    </ul>
                  </div>
                </>
              )}
          </div>
        </div>
        {Number(roles) !== 5 &&
          (partnerName?.toLocaleLowerCase() == LOCAL_CONSTANTS.PARTNERS.NIYO ||
            partnerName?.toLocaleLowerCase() == LOCAL_CONSTANTS.PARTNERS.VFS ||
            partnerName?.toLocaleLowerCase() ==
              LOCAL_CONSTANTS.PARTNERS.GLOBALTIX) &&
          activeTab !== 9 && (
            <>
              <div className={styles.mainTabbing}>
                {(partnerName?.toLocaleLowerCase() ==
                  LOCAL_CONSTANTS.PARTNERS.NIYO ||
                  partnerName?.toLocaleLowerCase() ==
                    LOCAL_CONSTANTS.PARTNERS.GLOBALTIX) &&
                  activeTab === 3 && (
                    <div className="leftTabbing ">
                      <div className={`${styles.topCms} ${styles.simBarTab}`}>
                        <ul>
                          {partnerName?.toLocaleLowerCase() !==
                            LOCAL_CONSTANTS.PARTNERS.GLOBALTIX && (
                            <li
                              className={
                                activeOrderTab === false ? styles.active : ""
                              }
                              onClick={() => {
                                setSearch("");
                                setPagination((prev) => ({ ...prev, skip: 0 }));
                                setActiveOrderTab(false);
                              }}
                            >
                              <p>Purchased eSim Order</p>
                            </li>
                          )}
                          <li
                            className={
                              activeOrderTab === true ? styles.active : ""
                            }
                            onClick={() => {
                              setSearch("");
                              setPagination((prev) => ({ ...prev, skip: 0 }));
                              setActiveOrderTab(true);
                            }}
                          >
                            <p>Free eSim Order</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                <div className=" d-flex align-items-center">
                  <div className="rightTabbing">
                    <input
                      type="search"
                      placeholder="Search by name or email"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPagination((prev) => ({ ...prev, skip: 0 }));
                      }}
                      value={search}
                    />
                  </div>
                  {partnerName?.toLocaleLowerCase() ==
                    LOCAL_CONSTANTS.PARTNERS.VFS && (
                    <button
                      className={` mx-3 mb-0 ${styles.loyalityPointBtn}`}
                      onClick={handleChinaExport}
                      disabled={loading}
                    >
                      Export
                    </button>
                  )}
                  <span className="text-light mx-3">
                    Total User Count:{activeData.count}
                  </span>
                </div>
              </div>

              <div className={styles.mainTabbing}>
                {partnerName?.toLocaleLowerCase() ==
                  LOCAL_CONSTANTS.PARTNERS.NIYO &&
                  activeTab === 3 && (
                    <>
                      <div className="leftTabbing ">
                        <div className={`${styles.topCms} ${styles.simBarTab}`}>
                          <ul>
                            <li
                              // className={activeOrderTab === false ? styles.active : ""}
                              onClick={handleExcelExport}
                            >
                              <p>Order Export</p>
                            </li>
                            {activeOrderTab && (
                              <li
                                // className={activeOrderTab === false ? styles.active : ""}
                                onClick={() =>
                                  navigate(
                                    `/partners/${partnerName}/${partnerWebsiteId}/upsell-records`
                                  )
                                }
                              >
                                <p>All Upsell Records</p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className={styles.rightFilterTabbing}>
                        <div className={styles.formDate}>
                          <div className={styles.inputGoup}>
                            <label>Start Date</label>
                            <input
                              type="date"
                              placeholder="start date"
                              onChange={(e) => {
                                setDateFilter({
                                  ...dateFilter,
                                  startDate: e.target.value,
                                  submit: false,
                                });
                              }}
                              value={dateFilter?.startDate}
                            />
                          </div>

                          <div className={styles.inputGoup}>
                            <label>End Date</label>
                            <input
                              type="date"
                              placeholder="end"
                              onChange={(e) => {
                                setDateFilter({
                                  ...dateFilter,
                                  endDate: e.target.value,
                                  submit: false,
                                });
                              }}
                              value={dateFilter?.endDate}
                            />
                          </div>
                          <div className={styles.inputGoup}>
                            <input
                              type="submit"
                              className="btn btn-success"
                              value="Filter"
                              onClick={handleFilter}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}

        {Number(roles) !== 5 && activeTab == 1 && (
          <UserList
            loading={loading}
            users={activeData}
            partnerType={partnerName?.toLocaleLowerCase()}
          />
        )}
        {Number(roles) !== 5 && activeTab == 2 && (
          <UserList
            loading={loading}
            users={activeData}
            partnerType={partnerName?.toLocaleLowerCase()}
          />
        )}
        {activeTab == 3 && (
          <OrderList
            loading={loading}
            orders={activeData}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
          />
        )}
        {(activeTab == 7 || activeTab == 8) && (
          <VFSPhilipCouponList
            loading={loading}
            orders={activeData}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
            activeTab={activeTab}
          />
        )}
        {activeTab == 4 && <PartnersConfigration />}
        {Number(roles) !== 5 &&
          activeTab != 4 &&
          activeTab != 5 &&
          !loading && (
            <Pagination
              paginationState={pagination}
              setPaginationState={setPagination}
              count={activeData.count}
            />
          )}
        {activeTab == 9 && <WeeklyESIMChart />}
      </div>
    </Layout>
  );
};

export default UsersList;
