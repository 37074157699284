import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { IMAGES } from "../../../assets/images";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

interface UserListProp {
  loading: boolean;
  users: any,
  partnerType: any
}

const UserList = ({ loading, users, partnerType }: UserListProp) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.scrollTable}>
      <table>
        <thead>
          <tr>
            <th>#</th>
            {partnerType == LOCAL_CONSTANTS.PARTNERS.VFS &&
              <>
                <th>Name</th>
                <th>Phone Number</th>
              </>
            }
            <th>Email</th>
            <th>Registration Source</th>
            <th>Payment Status</th>
            {partnerType == LOCAL_CONSTANTS.PARTNERS.VFS &&
              <>
               <th>Buying Date</th>
                <th>travelling Date</th>
                <th>Return Date</th>
                <th>Fapio Needed</th>
              </>
            }
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={7} align="center" className={styles.loadingText}>
                {LOCAL_CONSTANTS.loading}
              </td>
            </tr>
          )}
          {!loading && users.list.length === 0 && (
            <tr>
              <td colSpan={7} align="center">
                No User Found
              </td>
            </tr>
          )}
          {!loading &&
            users.list.map((item: any, index: number) => (
              <tr key={item._id}>
                <td>{index + 1}</td>
                {partnerType == LOCAL_CONSTANTS.PARTNERS.VFS &&
                  <>
                    <td>
                      <img
                        width={10}
                        height={10}
                        src={
                          item.avatar && item.avatar.length
                            ? item.avatar
                            : IMAGES.RegisteredUserImage
                        }
                        alt="userImage"
                      />
                      {item.firstName} {item.lastName || LOCAL_CONSTANTS.na}
                    </td>
                    <td>{item.countryCode} {item.phoneNumber || LOCAL_CONSTANTS.na}</td>
                  </>
                }
                <td>{item.email || LOCAL_CONSTANTS.na}</td>
                <td>{item.registrationType == 1 ? "Normal" : item.registrationType == 2 ? "Free" : LOCAL_CONSTANTS.na}</td>
                <td>{item.paymentProof === "" ? <span className="text-warning">Free</span> :  <span className="text-success">Paid</span> || LOCAL_CONSTANTS.na}</td>

                {partnerType == LOCAL_CONSTANTS.PARTNERS.VFS &&
                  <>
                   <td>{item?.createdAt ? moment(item?.createdAt).format(LOCAL_CONSTANTS.dateFormat) : "N/A"}</td>
                    <td>{item?.travellingDate ? moment(item?.travellingDate).format(LOCAL_CONSTANTS.dateFormat) : "N/A"}</td>
                    <td>{item?.returnDate ? moment(item?.returnDate).format(LOCAL_CONSTANTS.dateFormat) : "N/A"}</td>
                    <td>{item?.fapio || "N/A"}</td>
                  </>
                }
                {/* <td><a onClick={() => navigate(`/partners/${item?._id}/orders-list`)}>View Orders</a></td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default UserList