import { PlanTypes, CommonTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const tabList = (): Promise<PlanTypes.HomeTabResponse> => {
  return axios.get(API_ROUTES.HOME_TABS.LIST);
}

export const updateTabList = (tabId: string, payload: PlanTypes.UpdateHomeTabPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(`${API_ROUTES.HOME_TABS.LIST}/${tabId}`, payload);
}

export const getPlans = (query: PlanTypes.PlanListQuery): Promise<PlanTypes.PlanListResponse> => {
  return axios.get(API_ROUTES.PLANS.LIST, { params: query });
}

export const updatePlan = (payload: any, planId: string): Promise<any> => {
  return axios.put(`${API_ROUTES.PLANS.UPDATE}/${planId}`, payload);
}

export const updatePlanDetails = (payload: PlanTypes.PlanDetailsUpdatePayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(`${API_ROUTES.PLANS.UPDATE}`, payload);
}

export const getCountryRegionsPlanDetails = (query: PlanTypes.CountryRegionsPlanDetailsQuery): Promise<PlanTypes.PlanDetailsResponse> => {
  return axios.get(`${API_ROUTES.PLANS.DETAILS}`, { params: query });
}

export const popularCountriesUpdate = (payload: PlanTypes.UpdatePopularCountriesPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(API_ROUTES.COUNTRIES.POPULAR_COUNTRIES_UPDATE, payload);
}

export const updatePopularCountries = (payload: PlanTypes.UpdatePopularCountriesPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(API_ROUTES.COUNTRIES.UPDATE_POPULAR_COUNTRIES, payload);
}

export const getGeoCounries = (params: any): Promise<any> => {
  return axios.get(`/regions/countries`, { params});
}
export const putGeoCounries = (params: any): Promise<any> => {
  return axios.put(`/regions/countries`, params);
}

export const getGeoRegions = (params: any): Promise<any> => {
  return axios.get(`/regions`, { params});
}

export const putGeoRegions = (params: any): Promise<any> => {
  return axios.put(`/regions/updateRegion`, params);
}

export const getSortingCountryList = (countryId?: string): Promise<any> => {
  const url = countryId 
    ? `country-attachments/country/${countryId}` 
    : `country-attachments/country/default`;

  return axios.get(url);
};
export const addCountryFromSelect = (params: { 
  countryId: string | null; 
  isDefault: boolean; 
  iso: string; 
  attachedCountries: { countryId: string }[];  
}): Promise<any> => {
  return axios.post(`country-attachments`, {
    ...params,
    countryId: params.countryId ?? null, // Ensure `null` is explicitly sent if countryId is missing
  });
};


export const updateCountryFromDragDrop = (
  regionId: string | null,
  attachedCountries: { countryId: string; sortNumber: number }[]
): Promise<any> => {
  const id = regionId ?? "default"; // Use "null" or another placeholder if regionId is not provided

  return axios.put(`/country-attachments/${id}/sort-order`, { attachedCountries });
};


export const deleteCountryFromDragDrop = (
  countryId: string | null,
  countryToDeleteId: string
): Promise<any> => {
  const id = countryId ?? "default"; // Use "null" or another placeholder if countryId is not provided

  return axios.delete(`/country-attachments/${id}/detach/${countryToDeleteId}`);
};


